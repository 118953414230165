/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Importing Images
import anuLogo from "./assets/images/anuLogo.png";
import uiLogo from "./assets/images/uiLogo.png";
import australianAiAwardsLogo from "./assets/images/australianAiAwardsLogo.png";
import facebookLogo from "./assets/images/facebookLogo.png";
import quoraLogo from "./assets/images/quoraLogo.png";
import airbnbLogo from "./assets/images/airbnbLogo.png";
import codeInLogo from "./assets/images/codeInLogo.webp";
import googleAssistantLogo from "./assets/images/googleAssistantLogo.webp";
import pwaLogo from "./assets/images/pwaLogo.webp";
// Add more imports as needed

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Ryan Putra Budianto",
  title: "Hi all, I'm Ryan",
  subTitle: emoji(
    "A front-end developer passionate about UI/UX design, web development, and solving complex tech problems with creativity and expertise in React, Spring Boot, and Svelte."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1f5WGt9yZZQb8QjfViaaLXO4pQNwCFcd3/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/ryanputraa",
  linkedin: "https://www.linkedin.com/in/ryan-putra-budianto-6bb6811b7/",
  gmail: "ryanpbudianto@gmail.com",
  // gitlab: "https://gitlab.com/ryanputraa", // Updated if applicable
  // facebook: "https://www.facebook.com/ryanputraa", // Updated if applicable
  // medium: "https://medium.com/@ryanputraa", // Updated if applicable
  // stackoverflow: "https://stackoverflow.com/users/your-stackoverflow-id", // Update with your actual URL
  // Instagram, Twitter, and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "FRONT-END DEVELOPER WITH A KNACK FOR UI/UX DESIGN",
  skills: [
    emoji("⚡ Building interactive and responsive web interfaces"),
    emoji("⚡ Combining advanced computing knowledge with graphic design skills"),
    emoji("⚡ Developing applications using React, Spring Boot, and Svelte")
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
  https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "spring",
      fontAwesomeClassname: "fas fa-leaf" // Placeholder for Spring Boot
    },
    {
      skillName: "svelte",
      fontAwesomeClassname: "fas fa-code" // Placeholder for Svelte
    },
    // Add more skills as needed
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Australian National University",
      logo: anuLogo,
      subHeader: "Bachelor of Advanced Computing (Honours)",
      duration: "July 2022 - Present",
      desc: "Courses include Human-Computer Interaction, Computer Vision, Algorithms.",
      descBullets: [
        "Engaged in advanced computing projects and research.",
        "Participated in collaborative projects focusing on UI/UX design."
      ]
    },
    {
      schoolName: "University of Indonesia",
      logo: uiLogo,
      subHeader: "Bachelor of Science, Computer Science",
      duration: "August 2020 - June 2022",
      desc: "Courses include Data Structures, Computer Networks, Object-Oriented Programming.",
      descBullets: [
        "Developed foundational programming and software development skills.",
        "Participated in hackathons and coding competitions."
      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, // Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", // Insert stack or technology you have experience in
      progressPercentage: "80%" // Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "50%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, // Set it to true to show workExperiences Section
  experience: [
    {
      role: "Developer & Spokesperson",
      company: "NPG Data Visualization Project",
      companylogo: anuLogo,
      date: "July 2023 - May 2024",
      desc: "Developed a data visualizer web application for a non-technical client, ensuring smooth project communication and management.",
      descBullets: [
        "Led the front-end development using React and Svelte.",
        "Collaborated with backend developers to integrate APIs."
      ]
    },
    {
      role: "Tutor, Programming Foundations 2",
      company: "University of Indonesia",
      companylogo: uiLogo,
      date: "February 2023 - July 2023",
      desc: "Guided students through programming concepts, tailoring explanations to improve comprehension and practical skills.",
      descBullets: [
        "Created comprehensive lesson plans and tutorials.",
        "Assisted students with coding assignments and projects."
      ]
    },
    {
      role: "Teaching Assistant, Data Structures and Algorithms",
      company: "University of Indonesia",
      companylogo: uiLogo,
      date: "February 2022 - July 2022",
      desc: "Supported students by creating assignments, proctoring exams, and providing one-on-one assistance to enhance understanding of algorithms.",
      descBullets: [
        "Organized study groups and review sessions.",
        "Provided feedback on student projects and assignments."
      ]
    }
  ]
};

// Open Source Section

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Big Projects Section

const bigProjects = {
  display: false, // Set false to hide this section, defaults to true
  // If you set display to true, you can add projects here
  projects: []
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements 🏆"),
  subtitle: "Some accomplishments and awards",

  achievementsCards: [
    {
      title: "ANU Chancellor's International Scholarship",
      subtitle: "Awarded for academic excellence",
      image: anuLogo,
      imageAlt: "ANU Chancellor's International Scholarship",
      footerLink: [
        {
          name: "Scholarship Details",
          url: "https://science.anu.edu.au/study/scholarships/anu-chancellors-international-scholarship"
        }
        // You can add extra buttons here.
      ]
    },
    {
      title: "Australian AI Awards 2024 Finalist",
      subtitle:
        "AI Innovator - Information Technology category, ANU Techlauncher Program",
      image: australianAiAwardsLogo,
      imageAlt: "Australian AI Awards 2024 Finalist",
      footerLink: [
        {
          name: "Award Details",
          url: "https://australianaiawards.com.au/"
        }
      ]
    }
    // Add more achievement cards as needed
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle: "I enjoy writing and sharing my experiences in development.",
  displayMediumBlogs: "false", // Set true to display fetched medium blogs instead of hardcoded ones
  display: false, // Set false to hide this section, defaults to true
  blogs: [
    // Add your blog posts here if display is true and displayMediumBlogs is false
    {
      url: "https://blog.example.com/your-blog-post",
      title: "Your Blog Post Title",
      description: "A brief description of your blog post."
    }
    // Add more blog objects as needed
  ]
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji("I enjoy speaking about tech and sharing knowledge."),
  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
    // Add more talks if you have any
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "Tech discussions and personal insights",

  // Please Provide with Your Podcast embedded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

// Resume Section

const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",

  // You can add more details or links if needed
  display: true // Set false to hide this section, defaults to true
};

// Contact Info

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+61-498-716-092",
  email_address: "ryanpbudianto@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "ryanputra", // Replace with your Twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be displayed as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  resumeSection,
  contactInfo,
  twitterDetails,
  isHireable
};
